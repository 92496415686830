import './about.scss'
import {getPropsFromState} from "../../redux/mapStateToProps";
import {GetAbout, SendCall} from "../../redux/actions";
import {connect} from "react-redux";
import {useEffect} from "react";
import {generateFileMediaUrl, generateImageMediaUrl} from "../../utils/generateMediaUrl";
import React from "react";
import {InputGroup} from "../../components/uiElements/inputGroup/InputGroup";
import {LoadingOutlined} from "@ant-design/icons";
import {useState} from "react";
import isEmail from "validator/es/lib/isEmail";

function About(props){
    const {staticTexts,about,partners,requestLoading} = props

    const [fieldsData,setFieldsData] = useState({
        fullName:'',
        email:'',
    })
    const [error,setError] = useState({
        fullName:false,
        email:false,
    })


    useEffect(()=> {
        props.GetAbout()
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    function validate() {
        const error = {};
        let result = true;
        Object.keys(fieldsData).forEach(key => {
            if (!fieldsData[key] || (key === 'email' && !isEmail(fieldsData?.email)) ||
                (key === 'phone' && fieldsData.phone.length < 5 )){
                error[key] = true;
                result = false;
            }

        })
        setError(error);
        return result;
    }

    function sendVisit() {
        if(validate()){
            props.SendCall(fieldsData).then(() => {
            }).finally(() => {
                setFieldsData({
                    fullName:'',
                    email:'',
                })
            })
        }
    }

    function getInputValues(e) {
        const {name, value} = e.target
        if (name === "phone" && value && !/^[+\d]\d*$/.test(value)) {
            return
        }
        setFieldsData({
            ...fieldsData,
            [name]: value
        })
        setError({
            ...error,
            [name]: false
        })
    }


    return <div className={'about-wrapper'}>
        <div className={'about-header'}>
            <div className={'about-title'}>
                {about?.title}
            </div>
            <div className={'about-description'}>
                {about?.description}
            </div>
        </div>
        <div className={'about-blocks-wrapper'}>
            {
                about?.blocks?.length && about?.blocks?.map((item,index) => {
                    return <div className={'about-blocks-card'} key={index}>
                        <div className={'about-blocks-description'}>
                            {item?.description}
                        </div>
                        <div className={'about-blocks-img'}>
                            <div className={'about-img'}>
                                <img src={generateFileMediaUrl(item?.mediaMain?.path)} alt=""/>
                            </div>
                        </div>
                    </div>
                })
            }
        </div>
        <div className={'about-partners-block'}>
            <div className={'about-partners-header'}>
                <div className={'about-partners-title'}>
                    {staticTexts?.about_us_partners_title}
                </div>
                <div className={'about-partners-description'}>
                    {staticTexts?.about_us_partners_description}
                </div>
            </div>
            <div className={'about-partners-wrapper'}>
                {
                    partners?.length && partners?.map(item => {
                        return <div className={'about-partners-card'} key={item?.id}>
                            <div className={'about-partners'}>
                                <img src={generateImageMediaUrl(item?.mediaMain?.path)}
                                     title={item?.mediaMain?.title ? item?.mediaMain?.title : ''}
                                     alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''}/>
                            </div>
                        </div>
                    })
                }
            </div>
            {/*<div className={'about-feedback'}>*/}
            {/*    <div className={'feedback-info'}>*/}
            {/*        <div className={'feedback-title'}>*/}
            {/*            {staticTexts?.about_us_feedback_title}*/}
            {/*        </div>*/}
            {/*        <div className={'feedback-description'}>*/}
            {/*            {staticTexts?.about_us_feedback_description}*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className={'feedback-input'}>*/}
            {/*        <InputGroup*/}
            {/*            inputType={'input'}*/}
            {/*            type={"text"}*/}
            {/*            value={fieldsData?.fullName}*/}
            {/*            error={error?.fullName}*/}
            {/*            name={'fullName'}*/}
            {/*            placeholder={staticTexts?.about_us_feedback_name_placeholder}*/}
            {/*            maxLength={100}*/}
            {/*            onChange={getInputValues}*/}
            {/*        />*/}
            {/*        <InputGroup*/}
            {/*            inputType={'input'}*/}
            {/*            type={"text"}*/}
            {/*            value={fieldsData?.email}*/}
            {/*            error={error?.email}*/}
            {/*            name={'email'}*/}
            {/*            placeholder={staticTexts?.homepage_contact_placeholder_email}*/}
            {/*            maxLength={100}*/}
            {/*            onChange={getInputValues}*/}
            {/*        />*/}

            {/*        <button className='sent-email'*/}
            {/*                onClick={() => {*/}
            {/*                    if (!requestLoading && sendVisit) {*/}
            {/*                        sendVisit()*/}
            {/*                    }*/}
            {/*                }}>*/}
            {/*            {staticTexts?.about_us_feedback_btn}*/}
            {/*            {*/}
            {/*                requestLoading ? <LoadingOutlined style={{fontSize: 14, color: "#fff"}}/>*/}
            {/*                    : ''*/}
            {/*            }*/}

            {/*        </button>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    </div>

}const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'requestLoading',
        'staticTexts',
        'services',
        'about',
        'partners',
    ])
};

const mapDispatchToProps = {
    GetAbout,
    SendCall
};


export default connect(mapStateToProps, mapDispatchToProps)(About);