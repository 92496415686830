// import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";

//import assets
import './header.scss'

// Import Components

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {Link, NavLink} from "react-router-dom";
import {ChangeLanguage} from "../../redux/actions";
import {Dropdown, Menu} from "antd";
import {BurgerMenuIcon, CloseMenuIcon, Logo} from "../../assets/images";
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";

function Header(props) {
    const {staticTexts, selectedLanguage, languages, toggleLanguage, mobileMenuOpen, toggleMobileMenu} = props;
    const [closeLanguage, setCloseLanguage] = useState(false)
    useEffect(() => {
        window.addEventListener("scroll", () => setCloseLanguage(true))
    }, [])

    const menuLanguage = (
        <Menu className={`languages-dropdown`}>
            {
                languages?.length && languages?.map((item) => {
                    return selectedLanguage?.code !== item.code &&
                        <Menu.Item key={item.id}
                                   onClick={() => {
                                       props.ChangeLanguage(item)
                                   }}
                                   className={'language-dropdown-item'}>
                            <div className="selected-language">
                                {/*<span>{item?.name}</span>*/}
                                <div className={'language_icon'}>
                                    <img src={generateImageMediaUrl(item?.iconPath?.path)}/>
                                </div>
                            </div>
                        </Menu.Item>
                })
            }
        </Menu>
    );

    console.log(languages?.length)

    return <div className={`header-wrapper ${mobileMenuOpen ? 'responsive' : ''}`}>
        <div className={'link-menu'}>
            <Link to={'/'} className={'hybrid-logo'}>
                <Logo title={''}/>
            </Link>
            <NavLink to={'/about'} activeClassName='active'>
                <li className="nav-item">{staticTexts?.header_item_about}</li>
            </NavLink>
            <NavLink to={'/services'} activeClassName='active'>
                <li className="nav-item">{staticTexts?.header_item_services}</li>
            </NavLink>
            <NavLink to={'/news'} activeClassName='active'>
                <li className="nav-item">{staticTexts?.header_item_news}</li>
            </NavLink>
            {/*<NavLink to={'/products'} activeClassName='active'>*/}
            {/*    <li className="nav-item">{staticTexts?.header_item_products}</li>*/}
            {/*</NavLink>*/}
        </div>
        <ul className="nav-menu">
            <NavLink to={'/contact'} className='contact-block'>
                <li className="telematic-text">{staticTexts?.header_item_contact}</li>
            </NavLink>

            {
                languages?.length > 1 ? <li className="nav-item">
                    <Dropdown overlay={menuLanguage}
                              trigger={['hover']}
                              overlayClassName={`${closeLanguage ? "close" : ""}`}
                              onClick={() => toggleLanguage(setCloseLanguage(false))}
                              placement={'bottom'}>
                        <a className="ant-dropdown-link">
                            <div className="selected-language">
                                <div className={'language_icon'}>
                                    <img src={generateImageMediaUrl(selectedLanguage?.iconPath?.path)}/>
                                </div>
                                <div>
                                </div>
                            </div>
                        </a>
                    </Dropdown>
                </li> : ''
            }
            <a className={`header_burger ${!mobileMenuOpen ? 'open-menu' : 'close-menu'}`}
               onClick={toggleMobileMenu}>
                {!mobileMenuOpen ? <BurgerMenuIcon/> : <CloseMenuIcon/>}
            </a>
        </ul>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'languages',
        'selectedLanguage',
        'services',
    ])
};
const mapDispatchToProps = {ChangeLanguage};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
